import { async } from '@firebase/util';
import { firebaseGetByID, firebaseSave } from '../components/Firebase';
import cloneDeep from 'lodash.clonedeep';



const store = {};

export async function storeSetStateForType(setStateForType, type) {
  if (!store[type]) store[type] = {};
  if (store[type].setState !== setStateForType) console.warn("A setStateForType function is already stored for this type, but you're trying to change it, which is probably not what you want. Check your logic.")
  store[type].setState = setStateForType;
}


export async function storeGetByID(type, id) {
  let obj = store[type] && store[type][id];
  if (obj) return cloneDeep(obj);

  const snapshot = await firebaseGetByID(type, id);
  obj = snapshot && snapshot.data();
  if (!obj) return;

  const objNew = cloneDeep(obj);
  if (!store[type]) store[type] = {};
  store[type][id] = objNew;
  if (store[type].setState) store[type].setState(objNew); // update the obj with the useState set function registered earlier in storeSetStateForType, if it was called 
  return objNew;
}


export async function storeSave(type, payload, id = null) {
  const docID = await firebaseSave(type, payload, id); // if id is not passed in, we need to capture the one created by Firestore
  if (!store[type]) store[type] = {};
  const objNew = cloneDeep(payload);
  store[type][docID] = objNew; // TODO: clean previously set serverTimestamp()s probably re-updates the timestamp if you modify something else in this object and submit it again through this function.
  if (store[type].setState) store[type].setState(objNew); // update the obj with the useState set function registered earlier in storeSetStateForType, if it was called 
}