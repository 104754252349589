import { useEffect, useState } from "react";
import "../styles/globals.css";
import { getCookie, setCookie, clearCookie } from "../utils/handleCookies";
import App, { AppContext, AppProps } from "../node_modules/next/app";
import { getParamFromUrl } from "../utils/getParamFromUrl";
import { useRouter } from "../node_modules/next/router";
import { ApiNetworkProvider } from "../node_modules/@elrondnetwork/erdjs-network-providers/out/apiNetworkProvider";
//import { WalletProvider } from "../node_modules/@elrondnetwork/erdjs-web-wallet-provider/out/walletProvider";
import { chainType, DAPP_INIT_ROUTE, networkConfig } from "../config/network";
import { Transaction } from "../node_modules/@elrondnetwork/erdjs/out/transaction";
import { TransactionWatcher } from "../node_modules/@elrondnetwork/erdjs/out/transactionWatcher";
// import io from '../node_modules/socket.io-client/dist/socket.io'
// Font awesome icons config
import { config } from '../node_modules/@fortawesome/fontawesome-svg-core/index';
import '@fortawesome/fontawesome-svg-core/styles.css'
import Footer from "../components/Footer";
config.autoAddCss = false

// ADDED JN
import { firebaseAuthGet } from '../components/Firebase';
import { onAuthStateChanged } from "firebase/auth";
import { storeGetByID, storeSave, storeSetStateForType } from '../components/FirestoreData';

// END: ADDED JN

// ADDED DAN
import Script from '../node_modules/next/script';
// END: ADDED DAN

function MyApp({ Component, pageProps }) {

  const router = useRouter();

  // Call this function when you want to refresh the data
  const refreshData = () => router.replace(router.basePath);
  // if(redirected from erlond)
  if (!router.pathname.includes("/account") && getParamFromUrl("address")) {
    // console.log('weh')
    refreshData();
  }

  // ADDED JN:

  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState(null);

  // an auth token is set, client side, upon login for use by the server,
  // so it can check the user status from there, and make Firestore saves
  // see general "how to" at: https://dev.to/theranbrig/server-side-authentication-with-nextjs-and-firebase-354m
  async function userInit(firebaseUser) {
    const firebaseCookieName = "fbu";
    setFirebaseUser(firebaseUser);

    if (firebaseUser) {
      const token = await firebaseUser.getIdToken();
      setCookie(firebaseCookieName, token, 14);
    } else {
      clearCookie(firebaseCookieName);
    }
  }


  const [userObj, setUserObj] = useState(null);
  

  const userInitData = async (user) => {
    let userObj = await storeGetByID('user', user.uid);
    if (!userObj) {
      userObj = { displayName: user.displayName };
      await storeSave('user', userObj, user.uid)
    }
    console.log('stored userObj: ', userObj);
  };

  // initialise Firebase auth, and useEffect so that it only runs on the client
  useEffect(() => {
    const auth = firebaseAuthGet();
    onAuthStateChanged(auth, async (user) => { // TODO: implement onIdTokenChanged instead: https://colinhacks.com/essays/nextjs-firebase-authentication
      storeSetStateForType(setUserObj, 'user'); // store this setUserObj so that its state is updated automatically on every storeGetByID and storeSave call
      userInit(user);
      if (user) userInitData(user);
      setLoading(false);
    });
  });



  // END: ADDED JN

  return (
    <>
    {/* This loading device triggers only on the client it to be
        false fixes the hydration problem where client and server don't match in /dashboard, however,
        also completely kills any SSR benefit - Google will only see this "" and not the app content.
        TODO FIX */}
      {loading ? (
        ""
      ) : (
        <Component {...{ ...pageProps, firebaseUser, userObj }} />
      )}
    </>
  );
}

export default MyApp;
