import { initializeApp, getApp } from "firebase/app"
import { firebaseConfig } from "../config/firebaseApp.config"
import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, serverTimestamp, arrayUnion, arrayRemove, getDoc } from "firebase/firestore";
import cloneDeep from "lodash.clonedeep";


let firebaseApp;
let firebaseDB;
let firebaseAuth;

export function firebaseInit() {
  if (firebaseApp) return firebaseApp;
  // Initialise Firebase
  try {
    firebaseApp = initializeApp(firebaseConfig);
  } catch (e) {
    firebaseApp = getApp();
  }
  return firebaseApp;
}


export function firebaseAuthGet() {
  if (firebaseAuth) return firebaseAuth;
  const app = firebaseInit();
  return firebaseAuth = getAuth();
}


function firebaseDBInit() {
  if (firebaseDB) return firebaseDB;
  const app = firebaseInit();
  // Initialize Cloud Firestore and get a reference to the service
  return firebaseDB = getFirestore(app);
}


export async function firebaseSave(docType, docPayload, docID = null, merge = false) {
  const db = firebaseDBInit();
  
  docPayload = cloneDeep(docPayload);
  docPayload.updated = serverTimestamp(); // add a timestamp to every save

  try {
    const docRef = docID ? doc(db, docType, docID) : doc(collection(db, docType));
    const mergeObj = merge ? { merge: true } : undefined; 
    await setDoc(docRef, docPayload, mergeObj); 
    docID = docID || docRef.id;
    console.log("Document or type " + docType + " written with ID: " + docID);
    return docID;
  } catch (e) {
    docID = docID || "[autogenerated]";
    console.error("Error adding document type " + docType + " with ID: " + docID, e);
  }
}


export async function firebaseGetByID(docType, docID) {
  const db = firebaseDBInit();

  const docRef = doc(db, docType, docID);
  const docResult = await getDoc(docRef);

  return docResult;
}

export {arrayUnion, arrayRemove};
