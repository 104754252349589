function getCookie(name, { defaultValue }) {
  const value = `; ${defaultValue ?? document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

function getAllCookies({ defaultValue }) {
  const cookies = defaultValue ?? document.cookie.split(";");
  const result = {};
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    result[name] = getCookie(name, { defaultValue });
  }
  return result;
}

function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}`;
}

function clearCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function clearAllCookies() {
  const cookies = document.cookie.split(";");
  console.log(cookies)
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    clearCookie(name);
  }
}

export { getCookie, getAllCookies, setCookie, clearCookie, clearAllCookies };
